@import "~antd/dist/antd.css";

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}
#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
.home {
  display: flex;
  margin: 0;
  flex-direction: column;
}
.home .bottom {
  flex-grow: 1;
}
.content {
  max-width: 1200px;
}
.breadcrumb {
  margin-left: 20px;
}
header.ant-layout-header {
  padding: 0 16px;
}
@media screen and (min-width: 600px) {
  .content {
    padding: 0 50px;
  }
  .breadcrumb {
    margin-left: 0px;
  }
  header.ant-layout-header {
    padding: 0 50px;
  }
  
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.tooltip > * {
  font-family: Arial, Helvetica, sans-serif;
  color: #000;
}
.tooltip td {
  padding-left: 8px;
}
